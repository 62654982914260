import { Component } from "solid-js";

const PrinterRoute: Component = () => {
  return (
    <button
      type="button"
      onClick={() => {
        throw new Error("Sentry Frontend Error");
      }}
    >
      Throw error
    </button>
  );
};

export default PrinterRoute;
